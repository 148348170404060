import React from 'react'
import {sanitizeFilename} from '../lib/helpers'

export default ({node}) => {
    if (!node || !node.file || !node.file.asset || !node.file.asset.url) { return null }
        
    // console.log('--> block: ', node)

    return (
        <div>
            {/* <FaFilePdf size={22} /> */}
            <a href={node.file && node.file.asset ? node.file.asset.url +'/'+ sanitizeFilename(node.file.asset.originalFilename) : '#'} target='_blank'>{node.title}</a>
        </div>
    )
}