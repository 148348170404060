import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import Figure from './Figure'
import BlockFile from './block-file'
import BlockFaq from './block-faq'
import {Link, navigate} from 'gatsby'

// const PortableText = ({blocks}) => (
//   <BasePortableText blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
// )

const PortableText = ({blocks}) => (
  <BasePortableText blocks={blocks} 
    serializers={{types: {
      authorReference: ({node}) => <span>{node.author.name}</span>,
      mainImage: Figure,
      fileComponent: BlockFile,
      faqs: BlockFaq,
      block: BlockRenderer,
    }}} {...clientConfig.sanity} 
  />
)

const BlockRenderer = (props) => {
  // console.log('---> block: ', props)
  const {node} = props
  if(node.markDefs[0] && node.markDefs[0]._type === 'link'){
    // console.log('---> node: ', node)
    if(node.markDefs[0].href.includes('mailto:')){
      let _link = <a href={node.markDefs[0].href || '#'}>{node.children[0].text || ''}</a>
      return <div>{_link}</div>
    }
    
    let _link = node.markDefs[0].href.includes('http://') || node.markDefs[0].href.includes('https://') ? 
      <a href={node.markDefs[0].href || '#'} target='_blank'>{node.children[0].text || ''}</a> :
      <Link to={node.markDefs[0].href || '#'}>{node.children[0].text || ''}</Link>
    return <div>{_link}</div>
  }

  // Fall back to default handling
  return BasePortableText.defaultSerializers.types.block(props)
}

export default PortableText
