import React from 'react'

export default ({node}) => {
    if (!node || !node.questions) { return null }
        
    // console.log('--> block: ', node)

    return (
        <>
        <h3>{node.title || "FAQ's"}</h3>
        <div className="accordion accordion-line" id="accordionFaq">

            {
                node.questions && node.questions.map((item, i) => (
                    <div key={i} className="accordion-item">
                        <h6 className="accordion-header" id={"heading-" + i}>
                            <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + i} aria-expanded="false" aria-controls={"collapse-" + i}>
                            {item.question || ''}
                            </button>
                        </h6>
                        <div id={"collapse-" + i} className="accordion-collapse collapse" aria-labelledby={"heading-" + i} data-bs-parent="#accordionFaq">
                            <div className="accordion-body">
                                {item.answer || ''}
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
        </>
    )
}